import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import auth from "../services/auth";
import { useLocalStorage } from "./useLocalStorage";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
	const [user, setUser] = useLocalStorage("billuser", null);
	const navigate = useNavigate();

	const login = async (data) => {
		let response = await auth.login(data.user_name, data.password);
		if (response.data) {
			setUser(response.data);
			navigate("/", { replace: true });
		} else {
			alert("Invalid!");
		}
	};

	const logout = () => {
		setUser(null);
		navigate("/", { replace: true });
	};

	const value = useMemo(
		() => ({
			user,
			login,
			logout,
		}),
		[user]
	);

	return (
		<AuthContext.Provider value={value}>{children}</AuthContext.Provider>
	);
};

export const useAuth = () => {
	return useContext(AuthContext);
};
